<template>
  <div id="tabs">
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      v-if="tables"
      tab-position="top"
    >
      <el-tab-pane
        :label="`${group}(${table.uploadNum}/${table.totalNum})`"
        :name="index.toString()"
        v-for="(table, group, index) in filtedTables"
        :key="group"
      >
        <el-table
          :data="table.list"
          :default-sort="{ prop: 'status', order: 'ascending' }"
          style="width: 100%"
          row-key="id"
          :tree-props="{ children: 'children' }"
        >
          <el-table-column width="24"></el-table-column>
          <el-table-column type="index" label="序号" width="80px"></el-table-column>
          <el-table-column
            prop="name"
            label="报表名称"
            show-overflow-tooltip
            min-width="40%"
          >
            <template slot-scope="scope"
              >{{ scope.row.tableNum }} {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" min-width="10%" sortable>
            <template slot-scope="scope">
              <span v-if="scope.row.status === '已上传'" class="status-success">
                <span class="circle"></span><span>已上传</span></span
              >
              <span v-else-if="scope.row.status === '待修改'" class="status-error"
                ><span class="circle circle-eidt"></span><span>待修改</span></span
              >
              <span v-else class="status-noUpload"
                ><span v-if="scope.row.status" class="circle circle-no"></span
                ><span>{{ scope.row.status }}</span></span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140px">
            <template slot-scope="scope">
              <template v-if="scope.row.status === '已上传'">
                <router-link
                  :to="{
                    name: manualTableRouteName,
                    params: {
                      templateID: scope.row.templateId,
                      from: manner,
                      productID: product.id ? product.id : 191,
                      productName: product.name || '无',
                      zqLocation,
                    },
                    query: {
                      tableName: scope.row.name,
                      zq: scope.row.zq,
                      productID: product.id ? product.id : 191,
                      productName: product.name,
                      bankID: product.bankID,
                      activeName: activeName,
                      redirectTo: $route.fullPath,
                    },
                  }"
                  class="btn-view-edit"
                  v-if="scope.row.name != '余额表'"
                  ><i class="el-icon-edit-outline" v-if="scope.row.status"></i
                ></router-link>
                <span
                  v-if="scope.row.name == '余额表'"
                  class="showBalances"
                  @click="showBalance(scope.row.zq)"
                  ><i class="el-icon-edit-outline" v-if="scope.row.status"></i
                ></span>
                <span
                  class="btn-remove"
                  @click="
                    handleBtnRemove(scope.row.name, scope.row.templateId, scope.row.zq)
                  "
                  ><i class="el-icon-delete"></i
                ></span>
                <!-- <span
                  class="btn btn-remove"
                  @click="handleBtnRemoveBalance"
                  v-if="scope.row.name=='余额表'"
                >删除</span>-->
              </template>
              <template v-else-if="scope.row.status === '待修改'">
                <router-link
                  :to="{
                    name: manualTableRouteName,
                    params: {
                      templateID: scope.row.templateId,
                      from: manner,
                      productID: product.id ? product.id : 191,
                      productName: product.name || '无',
                      zqLocation,
                    },
                    query: {
                      tableName: scope.row.name,
                      zq: scope.row.zq,
                      productID: product.id ? product.id : 191,
                      productName: product.name,
                      bankID: product.bankID,
                      redirectTo: $route.fullPath,
                    },
                  }"
                  class="btn-edit"
                  ><i class="el-icon-edit-outline" v-if="scope.row.status"></i
                ></router-link>
                <span
                  class="btn-remove"
                  @click="
                    handleBtnRemove(scope.row.name, scope.row.templateId, scope.row.zq)
                  "
                  ><i class="el-icon-delete"></i
                ></span>
              </template>
              <template v-else>
                <router-link
                  :to="{
                    name: manualTableRouteName,
                    params: {
                      templateID: scope.row.templateId,
                      from: manner,
                      productID: product.id ? product.id : 191,
                      productName: product.name || '无',
                      zqLocation,
                    },
                    query: {
                      tableName: scope.row.name,
                      zq: scope.row.zq,
                      productID: product.id ? product.id : 191,
                      productName: product.name,
                      bankID: product.bankID,
                      activeName: activeName,
                      redirectTo: $route.fullPath,
                    },
                  }"
                  class="btn-view-edit"
                  v-if="scope.row.name != '余额表'"
                  ><i class="el-icon-edit-outline" v-if="scope.row.status"></i
                ></router-link>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { cloneDeep } from "lodash";
import { deleteTableAi } from "@/api/api";

export default {
  props: {
    tables: Object,
    manner: [String],
    noborder: Boolean,
    forceShowAllTable: {
      //不需要经过筛选 直接显示所有的所得税表
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeName: "0",
    };
  },
  async mounted() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName;
    }
    //await this.$store.cache.dispatch("loadCompanyInfo");
  },
  computed: {
    ...mapState("UploadTable", ["zq", "product", "SDSSelection"]),
    //...mapState(["companyInfo", "authorization"]),
    zqLocation() {
      return this.$route.params["zqLocation"] || this.$route.params.zq;
    },
    filtedTables() {
      const tables = cloneDeep(this.tables);
      const sdsTables = tables.所得税表;
      if (!this.forceShowAllTable && sdsTables) {
        if (this.SDSSelection == null) {
          tables.所得税表.list = [];
        } else {
          tables.所得税表.list = sdsTables.list.filter((table) => {
            return (
              table.status !== "未上传" ||
              this.findInSDStablesByTemplateId(table.templateId)
            );
          });
        }
      }

      return tables;
    },
    manualTableRouteName() {
      return this.manner == "readonly" ? "admin-manual-fill-table" : "manual-fill-table";
    },
  },
  methods: {
    handleBtnRemoveBalance() {},

    showBalance(zq) {
      this.$emit("showDetailBalance", zq);
    },

    //是否已经被选中显示的所得税表格
    findInSDStablesByTemplateId(templateId) {
      return this.SDSSelection.find((SDStable) => {
        return SDStable.templateId == templateId;
      });
    },
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    //删除文件
    handleBtnRemove(filename, templateId, zq) {
      const _this = this;
      this.$confirm(`${filename} 将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const uuid = this.$route.params.uuid;
        const result = await deleteTableAi(
          // _this.companyInfo.nsrsbm,
          sessionStorage.getItem("nsrsbh"),
          zq,
          templateId,
          _this.authorization,
          uuid
        );

        if (result.code == 200) {
          _this.$emit("listChanged");
          _this.$message({
            type: "success",
            message: "删除成功!",
          });
        } else {
          _this.$message.error(result.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.scroll-area {
  width: 100%;
  height: 400px;
}

.btn-view-edit {
  text-align: center;
  width: auto;
  color: #2f54eb;
}

.btn-view-edit:hover {
  color: rgb(10, 55, 238);

  text-decoration: underline;
}

.btn-remove {
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.4);
}

.btn-remove:hover {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: underline;
}

.btn-edit {
  text-align: center;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  border-radius: 15px;
  padding: 0 10px;
  width: 80px;
  color: #fff;
  background: #ff6464;
}

.btn-edit:hover {
  background: rgb(252, 77, 77);
}

.status-success {
  color: rgba(0, 0, 0, 0.65);
}

.status-error {
  color: rgba(0, 0, 0, 0.65);
}

>>> .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}

>>> .el-tabs__item {
  padding: 0 30px;
}

.showBalances {
  color: #2f54eb;
  cursor: pointer;
}

.el-icon-delete,
.el-icon-edit-outline {
  font-size: 16px;
  margin-right: 24px;
}

.circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #52c41a;
  border-radius: 50%;
  margin-right: 8px;
}

.circle-no {
  background: #d9d9d9;
}

.circle-eidt {
  background: #f5222d;
}
</style>
