<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/tools/toolsAudit' }">小工具</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/tools/toolsAudit' }">审计小工具</el-breadcrumb-item>
          <el-breadcrumb-item>报表查看</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">报表查看</template>
      </main-header>
    </div>
    <div class="page-wrapper" :key="actualZq">
      <div class="content-body" :class="{ nomargin: uploadMethod == 1, noPadding: haveUploads }">
        <div class="uploadMethodText">
          <div>
            <router-link
              :to="{
                name: 'toolsTables',
                params: { bankId, version, zq, version, dataPeriod: 0, nsrsbm, uuid },
              }"
            >
              <div :class="{ zqLocation: dataPeriod == 0 }" class="uploadMethodItem">
                {{ zq.substring(0, 4) }}年报表
              </div>
            </router-link>
          </div>
          <div>
            <router-link
              :to="{
                name: 'toolsTables',
                params: { bankId, version, zq, version, dataPeriod: 1, nsrsbm, uuid },
              }"
            >
              <div :class="{ zqLocation: dataPeriod == 1 }" class="uploadMethodItem">
                {{ zq.substring(0, 4) - 1 }}年报表
              </div>
            </router-link>
          </div>
          <div>
            <router-link
              :to="{
                name: 'toolsTables',
                params: { bankId, version, zq, version, dataPeriod: 2, nsrsbm, uuid },
              }"
            >
              <div :class="{ zqLocation: dataPeriod == 2 }" class="uploadMethodItem">
                {{ zq.substring(0, 4) - 2 }}年报表
              </div>
            </router-link>
          </div>
        </div>
        <table-list
          :tables="tables"
          :forceShowAllTable="true"
          @listChanged="listChanged"
          manner="readonly"
          class="table-list fix-flexsR"
          :class="{ 'table-lists': haveUploads }"
          @showDetailBalance="showDetailBalance"
        ></table-list>
        <div v-if="showTables" class="containertables">
          <div class="tables">
            <div class="title">{{ balanceName }}</div>
            <div class="closes" @click="deleteTable">×</div>
            <div class="content">
              <el-table
                :data="tableData"
                style="width: 100%"
                height="620"
                row-key="subBsDm"
                :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              >
                <el-table-column prop="subBsDm" label="科目编码"></el-table-column>
                <el-table-column prop="subBsMc" label="科目名称"></el-table-column>
                <el-table-column label="期初余额" align="center">
                  <el-table-column prop="beforeBorrow" label="借方" align="right"></el-table-column>
                  <el-table-column prop="beforeLoan" label="贷方" align="right"></el-table-column>
                </el-table-column>
                <el-table-column label="本期发生额" align="center">
                  <el-table-column
                    prop="currentBorrow"
                    label="借方"
                    align="right"
                  ></el-table-column>
                  <el-table-column prop="currentLoan" label="贷方" align="right"></el-table-column>
                </el-table-column>
                <el-table-column label="期末余额" align="center">
                  <el-table-column prop="afterBorrow" label="借方" align="right"></el-table-column>
                  <el-table-column prop="afterLoan" label="贷方" align="right"></el-table-column>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { getTaxList } from "@/api/api";
import TableList from "@/pages/tools/components/tableList.vue";
import MainHeader from "@/pages/components/MainHeader";
import { previousZq, periodZq } from "@/utils/helper";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { loadTableDataAi } from "@/api/table";

import BreadCrumb from "@/components/BreadCrumb";

export default {
  data() {
    return {
      tables: {},
      isLoading: false,
      hasItemsInList: false,
      actualZq: 0,
      zqs: [],
      uploadMethod: sessionStorage.getItem("method") || 1,
      haveUploads: false,
      showTables: false,
      balanceName: "",
      tableData: [],
    };
  },
  components: {
    TableList,
    MainHeader,
    BreadCrumb,
  },
  props: {
    dataPeriod: [Number, String],
    zq: [Number, String],
    productName: String,
    bankId: String,
    uuid: String,
    version: [Number, String],
    nsrsbm: [Number, String],
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID", "SDSSelection"]),
  },

  async mounted() {
    await this.init();
  },
  methods: {
    ...mapMutations("UploadTable", ["setCorrectDialogVisible"]),

    // 查看余额表
    showDetailBalance(val) {
      this.balanceName = val.slice(0, 4) + "年余额表";
      this.detailZq = val;
      this.showTables = true;
      let token = localStorage.getItem("authorization");
      loadTableDataAi(val, "YEB", token, this.uuid).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
          return;
        }
        this.tableData = res.data.dataJson;
      });
    },
    // 关闭查看余额表
    deleteTable() {
      this.showTables = false;
    },

    async init() {
      this.isLoading = true;

      if (this.dataPeriod == 1) {
        //上个征期
        this.actualZq = previousZq(this.zq);
        this.zqs.push(this.zq);
        this.zqs.push(this.actualZq);
      } else if (this.dataPeriod == 0) {
        //当前征期
        this.actualZq = this.zq;
      } else if (this.dataPeriod == 2) {
        //   新增的上上期
        this.actualZq = periodZq(this.zq);
      }

      await this.loadList();
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    listChanged() {
      this.loadList();
    },
    async loadList() {
      //bankId, qykjzd, nsrsbh, zq
      const tables = await getTaxList(this.actualZq, this.nsrsbm, this.version);

      // 如果有上传的数据就展开表格

      if (tables.data && tables.msgCode != -1) {
        for (let i in tables.data) {
          // console.log(i);
          if (tables.data[i].uploadNum > 0) {
            this.haveUploads = true;
          }
        }
      }

      // 报税版隐藏余额表
      if (this.version == 3 && tables.data) {
        for (let i in tables.data) {
          if (i == "余额表") {
            delete tables.data[i];
          }
        }
      }

      //过滤未上传的表格
      this.tables = {};
      let i = 0;
      for (const grpName in tables.data) {
        i++;
        const grpData = tables.data[grpName];
        const newGrp = [];
        for (const table of grpData.tableInfo) {
          i++;
          //if (table.uploadStatus != "未上传") {
          const {
            tableNameCn,
            tableNameEn: templateId,
            uploadStatus: status,
            tableNum,
            zq,
            children,
          } = table;

          //整理一下children的格式
          const thechildren = children.map((child) => {
            i++;
            const {
              tableNameCn,
              tableNameEn: templateId,
              uploadStatus: status,
              tableNum,
              zq,
              children,
            } = child;
            return {
              name: `${tableNameCn}`,
              status,
              zq,
              templateId,
              tableNum,
              children,
              id: i,
            };
          });
          i++;

          const item = {
            name: `${tableNameCn}`,
            status,
            zq,
            templateId,
            tableNum,
            id: i,
            children: thechildren,
          };

          newGrp.push(item);
          //}
        }

        if (newGrp.length > 0) {
          this.tables[grpName] = {
            list: newGrp,
            totalNum: grpData.totalNum,
            uploadNum: grpData.uploadNum,
          };
        }
      }

      if (Object.keys(this.tables).length > 0) {
        this.hasItemsInList = true;
      } else {
        this.hasItemsInList = false;
      }
    },
  },
  watch: {
    $route: function (to, from) {
      window.scrollTo(0, 0);
      this.init();
    },
  },
};
</script>

<style lang="stylus" scoped>
.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 20px;
  margin-top: 0;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  padding-top: 0;
}

.table-list {
  margin-top: 24px;
}

.page-wrapper {
  padding-bottom: 84px;
  margin-top: 14px;
}

.nomargin {
  margin: 0 30px;
  padding: 0;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.uploadMethodText {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 590px;
  padding-top: 20px;
}

.uploadMethodItem {
  width: 174px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.08);
  border-radius: 18px 18px 0 0;
  cursor: pointer;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.zqLocation {
  color: #2F54EB;
}

.table-lists {
  flex: 1;
  margin-top: 0;
  padding-top: 20px;
  border-left: 1px solid rgba(217, 217, 217, 1);
}

// 余额表
.tables {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -550px;
  margin-top: -300px;
  width: 1158px;
  height: 700px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 2000;
  padding-bottom: 30px;
  overflow-y: auto;
}

.content {
  width: 1136px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  text-align: center;
  margin: 24px 0;
}

.closes {
  position: absolute;
  right: 25px;
  top: 17px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.noPadding {
  padding-right: 0;
}

>>> .el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text {
  top: 80%;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}
</style>
